@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply font-text;
  background: #F8F8F8;
}

svg{
  max-width: 100%;
  height: auto;
}

#marker {
  background-color: rgb(39, 19, 118);
  width: 1em;
  height: 1em;
  border-radius: 1em;
  animation: ripple 2s linear infinite;
  color: #fff;
  padding-top: 1em;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(39, 19, 118, 0.3),
    0 0 0 1em rgba(39, 19, 118, 0.3),
    0 0 0 3em rgba(39, 19, 118, 0.3),
    0 0 0 5em rgba(39, 19, 118, 0.3);
  }
  100% {
    box-shadow: 0 0 0 1em rgba(39, 19, 118, 0.3),
    0 0 0 3em rgba(39, 19, 118, 0.3),
    0 0 0 5em rgba(39, 19, 118, 0.3),
    0 0 0 8em rgba(39, 19, 118, 0);
  }
}

.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  @apply shadow-none transition-all;
}

.headroom {
  max-width: 100vw;
}

.headroom--unfixed {
  /*position: fixed;*/
  transform: translateY(0);
}

.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}

.headroom--pinned.headroom--scrolled {
  @apply shadow-lg;
}

.headroom--unpinned {
  /*position: fixed;*/
  transform: translateY(-100%);
}

.headroom--pinned {
  /*position: fixed;*/
  transform: translateY(0%);
}

.headroom--unfixed .hamburger {
  @apply text-white;
}

.headroom.headroom--scrolled {

}

.headroom-wrapper{
  z-index: 999;
  position: relative;
}

.headroom.headroom--scrolled .hamburger {
  @apply text-black;
}

.headroom .nav_logo {
  /*@apply lg:opacity-0;*/
}

.headroom.headroom--scrolled .nav_logo{
  @apply lg:opacity-100;
}

.headroom a.dropdown, .mobile_menu a {
  @apply text-dark;
}

.headroom a.dropdown:hover, .mobile_menu a:hover {
  @apply text-primary;
}

.headroom .logo-gold, .headroom.headroom--scrolled .logo-original {
  opacity: 1;
}

.headroom.headroom--scrolled .logo-gold, .headroom .logo-original {
  opacity: 0;
}
.project_filter{
  position: relative;
  @apply lg:text-left lg:min-w-[40%] lg:px-0 lg:text-left lg:pr-6 lg:uppercase font-text;
}
@media only screen and (min-width: 1024px) {
  .project_filter:after{
    content: '\ea50';
    font-family: 'Material Icons Round';
    @apply absolute text-accent right-0 text-2xl opacity-0 transition-all;
  }
  .project_filter:before{
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    @apply bg-secondary top-full left-0 opacity-0 transition-all;
  }

  .project_filter.active:after, .project_filter.active:before{
    @apply opacity-100;
  }
}
